.robot {
  display: flex;
  align-items: center;
  padding: 10px;
}

.robot img {
  margin-right: 10px;
}

.comment {
  background-color: #dcf8c6;
  color: #000000;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 10px;
  width: auto;
  line-height: 26px;
  font-size: 16px;
  flex-grow: 1;
}

.videoWrapper {
  display: grid;
  place-items: center;
}
