import { signal } from "@preact/signals";
import { useEffect, useRef, useState } from "preact/hooks";
import * as styles from "./recipe.module.css";

export interface Ingredient {
  item: string;
  ml?: number;
  oz?: string;
  genericQuantity?: string;
}

export interface Cocktail {
  error?: string;
  name: string;
  description: string;
  method: string;
  garnish: string;
  creator: string;
  glass: string;
  ingredients: Ingredient[];
  comment: string;
  colors: [string, string];
}

export function Recipe({ recipe }: { recipe: Cocktail }) {
  const [showRecipe, setShowRecipe] = useState(false);

  const {
    name,
    description,
    method,
    garnish,
    glass,
    ingredients,
    creator,
    colors = ["#FFA07A", "#000"],
  } = recipe;

  useEffect(() => {
    if (showRecipe) {
      footRef.current?.scrollIntoView();
    }
  }, [showRecipe]);

  const footRef = useRef<HTMLDivElement>(null);

  return (
    <div
      class={styles.recipeCard}
      style={{ "--background": colors[0], "--foreground": colors[1] }}
    >
      <h2>{name}</h2>
      <p class={styles.description}>{description}</p>
      <p>
        <label>Creator:</label> {creator}
      </p>
      <section class={showRecipe ? styles.showRecipe : ""}>
        <p>{method}</p>
        <p>
          <label>Garnish:</label> {garnish}
        </p>
        <p>
          <label>Glass:</label> {glass}
        </p>
        <table>
          <thead>
            <tr>
              <th>Ingredient</th>
              <th colSpan={2}>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {ingredients.map((ingredient) => (
              <tr>
                <td>{ingredient.item}</td>
                {ingredient.genericQuantity
                  ? <td colSpan={2}>{ingredient.genericQuantity}</td>
                  : (
                    <>
                      <td>{ingredient.ml} ml</td>
                      <td>{ingredient.oz} oz</td>
                    </>
                  )}
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <div
        class={styles.showRecipeButtonSection}
      >
        <button
          onClick={() => setShowRecipe((val) => !val)}
        >
          {showRecipe ? "Hide recipe" : "Show recipe"}
        </button>
      </div>
      <div ref={footRef} />
    </div>
  );
}
