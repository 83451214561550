import { comment, robot } from "./robot.module.css";
import icon from "./robot.png";
export function Robot({ message }: { message: string }) {
  return (
    <div class={robot}>
      <img src={icon} width="100" height="100" alt="robot" />
      <div class={comment}>{message}</div>
    </div>
  );
}
