import { Robot } from "./components/Robot";
import { Human } from "./components/Human";
import { computed, signal } from "@preact/signals";
import { Cocktail, Recipe } from "./components/Recipe";
import "./style.css";
import "./fonts/stylesheet.css";
import { Loader } from "./components/Loader";
import broom from "./broom.svg";
import cheers from "./cheers.svg";
import fixtureJson from "./fixture.json";
import { useEffect, useRef, useState } from "preact/hooks";

const greetings = [
  "Welcome! What can I get for you today?",
  "Hello there! It's great to see a new face. What can I make for you?",
  "Greetings! What can I get started for you today?",
  "Welcome! We have a great selection of drinks. What can I get for you?",
  "Hi there! Let me know what you're in the mood for and I'll make it happen.",
  "Welcome! I'm here to serve you. What can I make for you?",
  "Hello! We have a wide variety of drinks available. What would you like to try?",
  "Good day! What can I pour for you today?",
  "Welcome! What can I mix up for you?",
  "Greetings! It's great to have you here. What can I get started for you?",
];

// const fixture = fixtureJson as Cocktail;
const fixture = null;
const suggestions = [
  "Negroni",
  "Something with elderflower",
  "A non-alcoholic drink with cucumber",
  "Bramble",
  "A tiki classic",
  "Invent something to enjoy at a beach BBQ",
  "I'd like something spicy",
];

const messageHistory = signal<
  Array<
    { type: "user" | "bot" | "error"; message: string } | {
      type: "recipe";
      recipe: Cocktail;
    }
  >
>(
  [
    {
      type: "bot",
      message: greetings[Math.floor(Math.random() * greetings.length)],
    },
  ],
);

const empty = computed(() => messageHistory.value.length < 2);

const loading = signal(false);

export function App() {
  const [message, setMessage] = useState("");

  async function sendMessage(text?: string) {
    if (!message && !text) {
      return;
    }

    const msg = text || message;

    setMessage("");

    messageHistory.value = [...messageHistory.value, {
      type: "user",
      message: msg,
    }];
    loading.value = true;

    const response = await fetch("/cocktail", {
      body: JSON.stringify({
        message: msg,
        history: messageHistory.value.filter((msg) => msg.type !== "bot").slice(
          -7,
          -1,
        ),
      }),

      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).catch((error) => {
      setMessage(msg);
      console.error(error);
    });

    loading.value = false;
    if (!response) {
      messageHistory.value = [...messageHistory.value, {
        type: "bot",
        message: "I'm sorry, I'm having trouble connecting to the server.",
      }];
      return;
    }

    const result: Cocktail = await response.json();
    console.log(result);

    if (result.error) {
      messageHistory.value = [...messageHistory.value, {
        type: "error",
        message: result.error,
      }];
    }
    if (result.comment) {
      messageHistory.value = [...messageHistory.value, {
        type: "bot",
        message: result.comment,
      }];
    }
    if (result?.ingredients?.length) {
      messageHistory.value = [...messageHistory.value, {
        type: "recipe",
        recipe: result,
      }];
    }
  }

  function handleSubmit(event: Event) {
    event.preventDefault();
    sendMessage();
  }

  function handleEnter(event: KeyboardEvent) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  }

  useEffect(() => {
    chatField.current?.scrollTo?.({
      top: chatField.current.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }, [messageHistory.value.length]);

  const chatField = useRef<HTMLDivElement>(null);
  return (
    <main>
      <div id="chat" ref={chatField}>
        {empty.value && (
          <>
            <h1>Mixie</h1>
            <h2>Your friendly AI bartender</h2>
          </>
        )}
        {messageHistory.value.map((message) => {
          if (message.type === "bot" || message.type === "error") {
            return <Robot message={message.message} />;
          } else if (message.type === "user") {
            return <Human message={message.message} />;
          } else if (message.type === "recipe") {
            return <Recipe recipe={message.recipe} />;
          }
        })}
        {empty.value && (
          <div id="suggestions">
            <p>Here are some ideas</p>
            {suggestions.map((suggestion) => (
              <button
                key={suggestion}
                onClick={() => sendMessage(suggestion)}
              >
                {suggestion}
              </button>
            ))}
          </div>
        )}
        {loading.value && <Loader />}
        {empty.value && (
          <blockquote>
            Mixie was created by{" "}
            <a href="https://twitter.com/ascorbic">Matt Kane</a>
            <div class="disclaimer">
              Mixie is an AI bot, so answers may be inaccurate or undrinkable.
              Use your judgment, and at your own risk. Icons by{" "}
              <a href="https://www.flaticon.com/">Flaticon</a>.
            </div>
          </blockquote>
        )}
        {empty.value && fixture && <Recipe recipe={fixture} />}
      </div>
      <form id="input" onSubmit={handleSubmit}>
        <button
          id="clear"
          label="Clear messages"
          title="Clear messages"
          onClick={() =>
            messageHistory.value = messageHistory.value.slice(0, 1)}
          disabled={loading}
        >
          <img src={broom} width={36} alt="Broom" />
        </button>

        <textarea
          disabled={loading}
          autoFocus
          id="message"
          onKeyPress={handleEnter}
          value={message}
          onInput={(event) => setMessage(event.currentTarget.value)}
        />
        <button
          id="send"
          type="submit"
          disabled={loading}
          label="Send"
          title="Send"
        >
          <img src={cheers} width={36} alt="Cheers" />
        </button>
      </form>
    </main>
  );
}
