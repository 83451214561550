.recipeCard {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
  border: 1px solid #ccc;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.recipeCard section {
  height: 50px;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.recipeCard section.showRecipe {
  padding-bottom: 60px;
  display: flex;
  height: auto;
  overflow: visible;
}

.recipeCard h2 {
  margin: -1rem -1rem 1rem -1rem;
  font-weight: 900;
  align-self: stretch;
  padding: 10px 20px;
  background-color: var(--background);
  color: var(--foreground);
}

.recipeCard p {
  margin: 0 0 1rem 0;
}

.recipeCard table {
  align-self: stretch;
  border-collapse: collapse;
}

.description {
  font-style: italic;
}

.recipeCard table th,
.recipeCard table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.recipeCard label {
  font-weight: bold;
}

.recipeCard table th {
  background-color: var(--background);
  color: var(--foreground);
}

.showRecipeButtonSection {
  display: flex;
  align-self: stretch;
  justify-content: center;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 1)
  );
  margin-top: -50px;
  padding: 10px;
}

.showRecipeButtonSection button {
  align-self: center;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background-color: var(--background);
  color: var(--foreground);
  font-size: 1rem;
  cursor: pointer;
}
