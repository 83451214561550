import { robot, videoWrapper } from "./robot.module.css";
import icon from "./robot.png";
import video from "url:./shaker.mp4";
export function Loader() {
  return (
    <div class={robot}>
      <img src={icon} width="100" height="100" alt="robot" />
      <div class={videoWrapper}>
        <video width={100} height={100} autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
