.human {
  display: flex;
  align-items: center;
  padding: 10px;
}

.human img {
  margin-left: 10px;
}

.comment {
  background-color: #9ad8ff;
  color: #000000;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 10px;
  width: auto;
  line-height: 26px;
  font-size: 16px;
  flex-grow: 1;
}
