import { comment, human } from "./human.module.css";
import icon from "./cocktail.png";
export function Human({ message }: { message: string }) {
  return (
    <div class={human}>
      <div class={comment}>{message}</div>
      <img src={icon} width="50" height="50" alt="person" />
    </div>
  );
}
